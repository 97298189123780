import React, { useEffect, useRef, useState, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import rrulePlugin from '@fullcalendar/rrule';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import Modal from './Modal'; 
import './Calendar.css';

const Calendar = ({ handleSelect, resources, date, onDateChange }) => {
  const calendarRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [events, setEvents] = useState([]);
  
  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi.getDate().toISOString().split('T')[0] !== date.toISOString().split('T')[0]) {
        calendarApi.gotoDate(date);
      }
    }
  }, [date]);

  useEffect(() => {
    const fetchEvents = async () => {
      const startDate = new Date(date);
      const endDate = new Date(date);
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 2);

      const startIso = startDate.toISOString().slice(0, 10);
      const endIso = endDate.toISOString().slice(0, 10);

      const url = `/datajson?start=${(startIso)}&end=${(endIso)}`;

      const response = await fetch(url);
      const data = await response.json();
      setEvents(data)
    };

    fetchEvents();
  }, [date]);

  const handleDatesSet = useCallback((info) => {
      onDateChange(info.view.currentStart);
  }, [onDateChange]);

  const resourceLabelDidMount = useCallback((info) => {
    if (info.resource.extendedProps.desc) {
      const questionMark = document.createElement('span');
      questionMark.innerHTML = ` <a href="javascript:void(0)" id="${info.resource.id}">info</a> `;
      info.el.querySelector('.fc-datagrid-cell-main').appendChild(questionMark);

      const link = document.querySelector(`a[id="${info.resource.id}"]`);
      link.onclick = () => {
        setModalContent(info.resource.extendedProps.desc);
        setIsModalOpen(true);
      };
    }
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  return (
    <div>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, resourceTimelinePlugin, rrulePlugin, interactionPlugin]}
        schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
        initialView='resourceTimeline'
        nowIndicator={true}
        businessHours={true}
        resourceAreaWidth='40%'
        height='auto'
        initialDate={date}
        selectable={true}
        selectOverlap={false}
        selectMirror={true}
        weekNumbers={true}
        locales={frLocale}
        titleFormat={{
          month: 'long',
          year: 'numeric',
          day: 'numeric',
          weekday: 'long'
        }}
        headerToolbar={{
          left: 'today',
          center: 'title',
          right: 'resourceTimelineDay'
        }}
        footerToolbar={{
          left: '',
          center: 'prev,next',
          right: ''
        }}
        locale='fr'
        resources={resources}
        events={events}
        resourceAreaColumns={[
          {
            group: true,
            field: 'floor',
            headerContent: 'Étage / Espace'
          },
          {
            headerContent: 'Salle'
          },
          { 
            field: 'occupancy',
            headerContent: 'Capacité'
          }
        ]}
        select={handleSelect}
        datesSet={handleDatesSet}
        resourceLabelDidMount={resourceLabelDidMount}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        content={modalContent}
      />
    </div>
  );
};

export default Calendar;
