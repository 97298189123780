// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tiers-summary {
    max-width: 500px;
    margin: 0 auto;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.tiers-summary__title {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1rem;
}

.tiers-summary__info {
    text-align: left;
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
}

.tiers-summary__info p {
    margin: 0.5rem 0;
}

.tiers-summary__info p strong {
    color: #333;
}

.tiers-summary button {
    width: 100%;
    max-width: 200px;
    margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/TiersSummary.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,yBAAyB;IACzB,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".tiers-summary {\n    max-width: 500px;\n    margin: 0 auto;\n    padding: 1.5rem;\n    background-color: #ffffff;\n    border-radius: 10px;\n    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n    text-align: center;\n}\n\n.tiers-summary__title {\n    font-size: 1.2rem;\n    color: #333;\n    margin-bottom: 1rem;\n}\n\n.tiers-summary__info {\n    text-align: left;\n    font-size: 1rem;\n    color: #555;\n    margin-bottom: 1.5rem;\n}\n\n.tiers-summary__info p {\n    margin: 0.5rem 0;\n}\n\n.tiers-summary__info p strong {\n    color: #333;\n}\n\n.tiers-summary button {\n    width: 100%;\n    max-width: 200px;\n    margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
