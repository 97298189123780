import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from './Button';
import './TiersForm.css'

function TiersForm({ onSubmitSuccess }) {
  const [formData, setFormData] = useState({
    name_contact: '',
    name: '',
    address: '',
    town: '',
    mobile: '',
    email: '',
    siret: '',
  });
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitted data:', formData);
    setIsSubmitting(true);

    try {
      const params = new URLSearchParams(location.search);
      const paramsStr = params.toString();
      const response = await fetch(`/create_thirdparty?${paramsStr}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        onSubmitSuccess(true);
      } else {
        console.error('Error submitting form:', response.statusText);
        onSubmitSuccess(false);
      }
    } catch(error) {
      console.error('Error submitting form:', error.message);
      onSubmitSuccess(false);
    }
  };

  return (
    <form className="tiers-form" onSubmit={handleSubmit}>
        <div className="tiers-form__field">
            <label htmlFor="name_contact">Votre Nom(*)</label>
            <input
            type="text"
            id="name_contact"
            name="name_contact"
            value={formData.name_contact}
            onChange={handleChange}
            required
            />
        </div>

        <div className="tiers-form__field">
            <label htmlFor="name">Nom de la société(*)</label>
            <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            />
        </div>

        <div className="tiers-form__field">
            <label htmlFor="email">Email(*)</label>
            <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            />
        </div>

        <div className="tiers-form__field">
            <label htmlFor="mobile">Numéro de téléphone(*)</label>
            <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="06 23 45 67 89"
            pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}|([0-9]{10})"
            required
            />
        </div>

        <div className="tiers-form__field">
            <label htmlFor="address">Adresse</label>
            <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            />
        </div>

        <div className="tiers-form__field">
            <label htmlFor="town">Ville</label>
            <input
            type="text"
            id="town"
            name="town"
            value={formData.town}
            onChange={handleChange}
            />
        </div>

        <div className="tiers-form__field">
            <label htmlFor="siret">Siret</label>
            <input
            type="text"
            id="siret"
            name="siret"
            value={formData.siret}
            onChange={handleChange}
            placeholder="552 178 639 00132"
            pattern='[0-9]{3} [0-9]{3} [0-9]{3} [0-9]{5}|([0-9]{14})'
            />
        </div>
        <Button type={"submit"} buttonDisabled={isSubmitting} text={"Finir la pré-réservation"}/>
        
    </form>
  );
};

export default TiersForm;
