import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import './ConfirmPage.css';
import Logo from '../components/Logo';

function ConfirmPage() {
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [initialMessage, setInitialMessage] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const handleConfirm = async () => {
        setLoading(true);
        setInitialMessage(false);
        const params = new URLSearchParams(location.search);
        const paramsStr = params.toString();
        
        try {
            const response = await fetch(`/confirm?${paramsStr}`);
            const data = await response.json();
            setStatus(data.status);
        } catch (error) {
            setStatus('error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="confirm-page">
            <header className='confirm-page-header'> 
                <Logo />
            </header>
            <main className='confirm-page-main'>
                <div className="confirm-message-container">
                    {loading ? (
                        <div className="loader"><h2>Chargement...</h2></div>
                    ) : initialMessage ? (
                        <h2>Veuillez cliquer sur le bouton ci-dessous pour confirmer la réservation.</h2>
                    ) : status === 'success' ? (
                        <>
                            <h2 className="success">Confirmation réussie</h2>
                            <p>La réservation a été confirmée. Un e-mail de confirmation a été envoyé au client.</p>
                        </>
                    ) : (
                        <>
                            <h2 className="error">Échec de la validation</h2>
                            <p>Une erreur est survenue lors de la confirmation. Merci de vérifier les détails et réessayer.</p>
                        </>
                    )}
                </div>

                <div className="button-container">
                    {initialMessage || loading ? (
                        <Button
                            text="Confirmer la réservation"
                            buttonDisabled={loading}
                            type="button"
                            onClick={handleConfirm}
                        />
                    ) : (
                        <Button
                            root="/"
                            text="Retour à la gestion des réservations"
                            buttonDisabled={false}
                            type="button"
                            onClick={() => navigate("/")}
                        />
                    )}
                </div>
            </main>
        </div>
    );
}

export default ConfirmPage;
