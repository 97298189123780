import React from 'react';
import Logo from '../components/Logo';
import ContactForm from '../components/ContactForm';
import './FormPage.css';

function FormPage() {
  return (
    <div className="form-page">
      <header className="form-page-header">
        <Logo />
      </header>
      <main className="form-page-main">
        <ContactForm />
      </main>
    </div>
  );
}

export default FormPage;