// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f4f6f9;
  }
  
.form-page-header {
    padding: 20px;
}
  
.form-page-main {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.contact-form-container {
    height: auto;
    padding: 0;
}

  `, "",{"version":3,"sources":["webpack://./src/pages/FormPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB;EAC3B;;AAEF;IACI,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,UAAU;AACd","sourcesContent":[".form-page {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    background-color: #f4f6f9;\n  }\n  \n.form-page-header {\n    padding: 20px;\n}\n  \n.form-page-main {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n}\n\n.contact-form-container {\n    height: auto;\n    padding: 0;\n}\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
