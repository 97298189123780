// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f9f9f9;
  padding: 10px;
}

.contact-form {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  max-width: 650px;
  width: 100%;
}

.contact-form h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group .form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.form-group textarea.form-control {
  height: 100px;
  resize: vertical;
}

.form-group input[type="checkbox"] {
  margin-right: 10px;
  vertical-align: middle;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirmation-message {
  color: #28a745; 
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uCAAuC;EACvC,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".contact-form-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background: #f9f9f9;\n  padding: 10px;\n}\n\n.contact-form {\n  background: #fff;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);\n  max-width: 650px;\n  width: 100%;\n}\n\n.contact-form h2 {\n  font-size: 24px;\n  color: #333;\n  margin-bottom: 20px;\n}\n\n.form-group {\n  margin-bottom: 15px;\n}\n\n.form-group label {\n  display: block;\n  font-weight: bold;\n  margin-bottom: 5px;\n}\n\n.form-group .form-control {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  font-size: 16px;\n}\n\n.form-group textarea.form-control {\n  height: 100px;\n  resize: vertical;\n}\n\n.form-group input[type=\"checkbox\"] {\n  margin-right: 10px;\n  vertical-align: middle;\n}\n\n.btn-container {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n}\n\n.confirmation-message {\n  color: #28a745; \n  font-size: 18px;\n  margin-top: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
