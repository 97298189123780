// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --fc-button-bg-color: #007BFF;
    --fc-button-border-color: #007BFF;
    --fc-button-hover-bg-color: #0056b3;
    --fc-button-hover-border-color: #0056b3;
    --fc-button-active-bg-color: #0056b3;
    --fc-button-active-border-color: #0056b3;
}

.fc-toolbar-title {
    color: black; 
    font-weight: bold; 
  }

.fc-highlight {
  background-color: rgba(0, 123, 255, 0.25) !important;
  border: 1px solid #007BFF;
}

.resource-description-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
  text-align: center;
}

.resource-description-popup p {
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Calendar.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,iCAAiC;IACjC,mCAAmC;IACnC,uCAAuC;IACvC,oCAAoC;IACpC,wCAAwC;AAC5C;;AAEA;IACI,YAAY;IACZ,iBAAiB;EACnB;;AAEF;EACE,oDAAoD;EACpD,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,iBAAiB;EACjB,sBAAsB;EACtB,aAAa;EACb,yCAAyC;EACzC,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":[":root {\n    --fc-button-bg-color: #007BFF;\n    --fc-button-border-color: #007BFF;\n    --fc-button-hover-bg-color: #0056b3;\n    --fc-button-hover-border-color: #0056b3;\n    --fc-button-active-bg-color: #0056b3;\n    --fc-button-active-border-color: #0056b3;\n}\n\n.fc-toolbar-title {\n    color: black; \n    font-weight: bold; \n  }\n\n.fc-highlight {\n  background-color: rgba(0, 123, 255, 0.25) !important;\n  border: 1px solid #007BFF;\n}\n\n.resource-description-popup {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: white;\n  border: 1px solid #ccc;\n  padding: 20px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n  width: 300px;\n  text-align: center;\n}\n\n.resource-description-popup p {\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
