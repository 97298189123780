// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tiers-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.tiers-form__field {
    margin-bottom: 15px;
}

.tiers-form__field label {
    display: block;
    font-weight: bold;
    margin-bottom: 6px;
    color: #333;
    font-size: 1rem;
}

.tiers-form__field input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 14px;
}

.tiers-form__field input:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.2);
}

@media screen and (max-width: 768px) {
    .tiers-form {
        padding: 15px;
    }

    .tiers-form__field input {
        padding: 8px;
        font-size: 13px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/TiersForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,yBAAyB;IACzB,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,yCAAyC;AAC7C;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,YAAY;QACZ,eAAe;IACnB;AACJ","sourcesContent":[".tiers-form {\n    max-width: 500px;\n    margin: 0 auto;\n    padding: 1.5rem;\n    background-color: #ffffff;\n    border-radius: 10px;\n    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n    text-align: center;\n}\n\n.tiers-form__field {\n    margin-bottom: 15px;\n}\n\n.tiers-form__field label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 6px;\n    color: #333;\n    font-size: 1rem;\n}\n\n.tiers-form__field input {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 6px;\n    box-sizing: border-box;\n    font-size: 14px;\n}\n\n.tiers-form__field input:focus {\n    border-color: #0056b3;\n    outline: none;\n    box-shadow: 0 0 5px rgba(0, 86, 179, 0.2);\n}\n\n@media screen and (max-width: 768px) {\n    .tiers-form {\n        padding: 15px;\n    }\n\n    .tiers-form__field input {\n        padding: 8px;\n        font-size: 13px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
