import React from 'react'
import './Button.css'

function Button({ buttonDisabled, text, type, onClick }) {
    return (
        <button
            type={type}
            disabled={buttonDisabled}
            className="button"
            onClick={onClick}>
            {text}
        </button>
    )
}

export default Button