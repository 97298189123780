// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-logo {
    left: 30px;
    top: 30px;
    position: static;
    width: 8%;
  }
  
@media (max-width: 1265px) {
  .header-logo {
    width: 100px;
  }
}
  

`, "",{"version":3,"sources":["webpack://./src/components/Logo.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,SAAS;EACX;;AAEF;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".header-logo {\n    left: 30px;\n    top: 30px;\n    position: static;\n    width: 8%;\n  }\n  \n@media (max-width: 1265px) {\n  .header-logo {\n    width: 100px;\n  }\n}\n  \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
