// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
    overflow: auto;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    max-height: 80vh; 
    overflow-y: auto; 
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 50px;
    cursor: pointer;
    color: black;
    transition: color 0.3s;
  }

  .close:hover {
    color: red; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,qCAAqC;IACrC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,wCAAwC;IACxC,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,eAAe;IACf,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.75);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    padding: 20px;\n    overflow: auto;\n  }\n  \n  .modal-content {\n    background: white;\n    padding: 20px;\n    border-radius: 10px;\n    max-width: 500px;\n    width: 100%;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    position: relative;\n    max-height: 80vh; \n    overflow-y: auto; \n  }\n  \n  .close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 50px;\n    cursor: pointer;\n    color: black;\n    transition: color 0.3s;\n  }\n\n  .close:hover {\n    color: red; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
