import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CalendarPage from './pages/CalendarPage';
import PreConfirmPage from './pages/PreConfirmPage';
import FormPage from './pages/FormPage';
import ConfirmPage from './pages/ConfirmPage';
import IntermediatePage from './pages/IntermediatePage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<CalendarPage />} />
        <Route path='/form' element={<FormPage />} />
        <Route path='/preconfirm' element={<PreConfirmPage />} />
        <Route path='/confirm' element={<ConfirmPage />} />
        <Route path="/intermediate" element={<IntermediatePage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
