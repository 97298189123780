import React from 'react';
import './Logo.css';

const Logo = () => {
  return (
    <img 
      src="https://beeplex.fr/images/derivatives/logo-image/800w/BeePlex.png" 
      alt="Logo" 
      className="header-logo" 
    />
  );
};

export default Logo;
