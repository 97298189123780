import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../components/Button';
import './TiersSummary.css';

function TiersSummary({ data, onSubmitSuccess }) {
    const location = useLocation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const params = new URLSearchParams(location.search);
            const paramsStr = params.toString();
            const response = await fetch(`/valid?${paramsStr}`);

            if (response.ok) {
                const data = await response.json();
                console.log(data.message);
                onSubmitSuccess(true);
            } else {
                console.error('Error submitting form:', response.statusText);
                onSubmitSuccess(false);
            }
        } catch(error) {
            console.error('Error submitting form:', error.message);
            onSubmitSuccess(false);
        }
    };

    return (
        <div className="tiers-summary">
            <h3 className="tiers-summary__title">Récapitulatif des informations du tiers</h3>
            <div className="tiers-summary__info">
                <p><strong>Nom :</strong> {data.name}</p>
                <p><strong>Adresse :</strong> {data.address}</p>
                <p><strong>Ville :</strong> {data.town}</p>
                <p><strong>Email :</strong> {data.email}</p>
                <p><strong>Téléphone :</strong> {data.phone}</p>
                <p><strong>SIREN :</strong> {data.idprof2}</p>
            </div>
            <Button text="Confirmer" buttonDisabled={isSubmitting} onClick={handleSubmit} />
        </div>
    );
}

export default TiersSummary;
